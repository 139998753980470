/**
 * AmeriHealth Caritas colors schema
 */

$app-color-primary: #4d4d4d;
$app-color-tone: lighten($app-color-primary, 5%);
$app-color-primary-light-1: #4d4d4d;
$app-color-primary-light-3: #eeeeee;

$app-color-white: #fff;
$app-color-black: #1f2020;
$app-color-gray-dark: #77706f;

$app-color-warning: #ffc107;
$app-color-danger: #dc3545;

$app-background-gradient: linear-gradient(90deg, $app-color-primary 0 , $app-color-primary 100%);