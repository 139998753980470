// .address-viewer .address
$address-viewer-address-min-width: 60%;

// .address-viewer .address .btn-control
$address-viewer-btn-control-font-size: 80%;
$address-viewer-btn-control-text-align: right;
$address-viewer-btn-control-text-transform: uppercase;
$address-viewer-btn-control-line-height: 1.5;
$address-viewer-btn-control-paddings: (
        "top": 0,
        "right": 0,
        "bottom": 0,
        "left": 0
);


// .address-viewer .unverified
$address-viewer-unverified-title-font-size: 80%;
$address-viewer-unverified-title-text-transform: uppercase;