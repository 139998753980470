//#24272a

$btn-text-transform: none;
$btn-border-radius: 0;

//.btn.btn-primary
$btn-primary-color: $white;
$btn-primary-background: #24272a;
$btn-outline-primary-border: #24272a;
//.btn::hover
$btn-primary-hover-background: #24272a;
$btn-primary-hover-color: $btn-primary-color;
$btn-outline-primary-hover-border: #24272a;

//.btn.btn-outline-primary
$btn-outline-primary-text-transform: uppercase;
$btn-outline-primary-letter-spacing: 1px;
$btn-outline-primary-background-color: transparent;
$btn-outline-primary-color: $app-color-primary-light-1;
$btn-outline-primary-border: 1px solid $app-color-primary-light-1;
//.btn.btn-outline-primary::hover
$btn-outline-primary-hover-color: $app-color-white;
$btn-outline-primary-hover-border: 1.5px solid $app-color-primary;
$btn-outline-primary-hover-background: $app-color-primary;